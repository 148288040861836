import { SeeTheCaseButton } from "@components/Testimonies/SeeTheCaseButton"
import Image from "gatsby-image"
import React from "react"
import Fade from "react-reveal/Fade"
import * as Styles from "./PortfolioSlides.styles"
import { FormattedMessage } from "gatsby-plugin-intl"

function PortfolioSlide({
  backgroundColor,
  highlightColor,
  titleColor,
  textColor,
  leftToRight,
  languages,
  image,
  company,
  linkToCase,
}) {
  const { description, altTextImage } = languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )
  return (
    <Styles.Background backgroundColor={backgroundColor} textColor={textColor}>
      <Styles.Wrapper leftToRight={leftToRight}>
        {image && (
          <Styles.ImageWrapper>
            <Fade {...{ [leftToRight ? "left" : "right"]: true }}>
              <Image alt={altTextImage} fluid={image.childImageSharp.fluid} />
            </Fade>
          </Styles.ImageWrapper>
        )}

        <Styles.TextWrapper>
          <Styles.Title color={titleColor} strongColor={highlightColor}>
            <FormattedMessage
              id="whatWeHaveDevelopedFor"
              values={{
                company: company,
                strong: words => <strong>{words}</strong>,
              }}
            />
          </Styles.Title>
          <Styles.Paragraph textColor={textColor}>
            {description}
          </Styles.Paragraph>
          <SeeTheCaseButton
            emptyIsHidden
            color={highlightColor}
            linkToCase={linkToCase}
          />
        </Styles.TextWrapper>
      </Styles.Wrapper>
    </Styles.Background>
  )
}

export default PortfolioSlide

import React from "react"
import { Box, Flex, Heading, Text } from "rebass"

export const Background = ({ backgroundColor, textColor, ...props }) => (
  <Flex
    sx={{
      width: "100vw",
      paddingY: 5,
      paddingX: 3,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: backgroundColor,
      color: textColor,
    }}
    {...props}
  />
)
export const Wrapper = ({
  backgroundColor,
  leftToRight,
  textColor,
  ...props
}) => (
  <Flex
    sx={{
      maxWidth: 1200,
      width: "100%",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: leftToRight ? "row" : "row-reverse",
      alignItems: "center",

      "& > *:not(:last-child)": {
        mr: leftToRight ? [0, 0, 5] : 0,
        ml: leftToRight ? 0 : [0, 0, 5],
      },
    }}
    {...props}
  />
)
export const ImageWrapper = props => (
  <Box
    sx={{
      width: ["100%", "50%", "45%"],
      mb: [4, 4, 0],
    }}
    {...props}
  />
)
export const TextWrapper = props => (
  <Box
    sx={{
      fontFamily: "body",
      width: ["100%", "100%", "45%"],
    }}
    {...props}
  />
)

export const Title = ({ strongColor, color, ...props }) => (
  <Heading
    sx={{
      fontSize: [4, 4, 6],
      mb: [4],
      color,
      maxWidth: "100%",
      strong: {
        color: strongColor,
      },
    }}
    {...props}
  />
)
export const Paragraph = props => (
  <Text
    sx={{
      mb: 4,
      fontSize: [3, 3, 4],
      fontFamily: "heading",
    }}
    {...props}
  />
)

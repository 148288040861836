import BaseLayout from "@components/BaseLayout"
import Hero from "@components/Hero"
import Portfolio from "@components/Portfolio"
import SEO from "@components/Seo"
import { graphql } from "gatsby"
import React from "react"

function PortfolioPage({ data }) {
  const {
    metatag,
    portfolio: { edges: portfolios },
  } = data
  const portfoliosList = portfolios.map(portfolio => portfolio.node.frontmatter)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )
  return (
    <BaseLayout
      hero={
        <Hero
          isClear
          title="portfolio.hero.title"
          subtitle="portfolio.hero.subtitle"
        />
      }
    >
      <SEO {...metatags} />
      <Portfolio portfolios={portfoliosList} />
    </BaseLayout>
  )
}
export const query = graphql`
  query portfolioQuery($language: String!) {
    metatag: markdownRemark(
      fileAbsolutePath: { glob: "**/content/metatags/portfolio.md" }
    ) {
      frontmatter {
        languages {
          language
          keywords
          title
          description
        }
      }
    }
    portfolio: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/portfolio/*.md" }
        frontmatter: {
          languages: { elemMatch: { language: { eq: $language } } }
        }
      }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            company
            backgroundColor
            highlightColor
            textColor
            titleColor
            linkToCase
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            languages {
              language
              description
              altTextImage
            }
          }
        }
      }
    }
  }
`
export default PortfolioPage

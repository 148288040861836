import React from "react"
import PortfolioSlide from "./PortfolioSlide"

export default function PortfolioSlides({ portfolios }) {
  return (
    <>
      {portfolios.map((portfolio, index) => (
        <PortfolioSlide
          key={index}
          {...portfolio}
          leftToRight={index % 2 !== 0}
        />
      ))}
    </>
  )
}

import { WidthLimitation } from "@components/Content"
import React from "react"
import { Flex } from "rebass"

export function Section({ children }) {
  return (
    <Flex width="100vw">
      <WidthLimitation mb="5" marginX="auto">
        <Flex flexWrap="wrap" justifyContent="space-between" width="100%">
          {children}
        </Flex>
      </WidthLimitation>
    </Flex>
  )
}

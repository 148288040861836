import Clients from "@components/Clients"
import React, { Fragment } from "react"
import * as Styles from "./Portfolio.styles"
import PortfoliosSlides from "./PortfolioSlides"

function Portfolio({ portfolios }) {
  return (
    <Fragment>
      <Styles.Section>
        <Clients isInPortfolio />
      </Styles.Section>
      <PortfoliosSlides portfolios={portfolios} />
    </Fragment>
  )
}

export default Portfolio
